import { Component } from '@angular/core';

import {NavController, Platform} from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateService } from '@ngx-translate/core';
import {Storage} from '@ionic/storage';
import {Router} from '@angular/router';

import * as firebase from 'firebase';

const firebaseconfig = {
  apiKey: 'AIzaSyBZDpS-20l2xF7J8I8W_HKdyG8tmmcOnNE',
  authDomain: 'bodarym-1245.firebaseapp.com',
  databaseURL: 'http://bodarym-1245.firebaseio.com',
  projectId: 'bodarym-1245',
  storageBucket: 'bodarym-1245.appspot.com',
  messagingSenderId: '941220110064',
  appId: '1:941220110064:web:409c2456616a33d0'
};

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})



export class AppComponent {

  lastTimeBackPress = 0;
  timePeriodToExit = 2000;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translateService: TranslateService,
    private navCtrl: NavController,
    private storage: Storage,
    private router: Router
  ) {

    this.initializeApp();

  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.backButtonEvent();
      // Language
      let userLang = navigator.language.split('-')[0];
      userLang = /(en|de|it|fr|es|be)/gi.test(userLang) ? userLang : 'es';
      console.log('LANGUAGE-->' + userLang);
      this.translateService.use(userLang);
      document.body.setAttribute('data-theme', 'light');
      this.statusBar.styleDefault();

      this.storage.get('usuario').then((val) => {
        console.log('usuario-->', val);

        if (val !== null) {
          console.log('navigate->home');
          this.navCtrl.navigateRoot('home');
          this.splashScreen.hide();

        } else {
          console.log('navigate->login');
          this.navCtrl.navigateRoot('login');
          this.splashScreen.hide();
        }

      });


    });
    firebase.initializeApp(firebaseconfig);
  }

  backButtonEvent() {
    console.log('activate backButton...');
    this.platform.backButton.subscribe(async () => {
      console.log('BackButton pressed');
      this.navCtrl.pop();
    });
  }



}
