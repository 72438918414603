import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {NavParams, ModalController, ToastController} from '@ionic/angular';

@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.page.html',
  styleUrls: ['./image-modal.page.scss'],
})
export class ImageModalPage implements OnInit {

  @ViewChild('slider', { read: ElementRef })slider: ElementRef;
  img: any;
  images: any;
  arrayimages: any[];
  indice: number;

  sliderOpts = {
    zoom: {
      maxRatio: 5
    }
  };

  constructor(private navParams: NavParams, private modalController: ModalController, private toastCtrl: ToastController) { }

  ngOnInit() {
    this.img = this.navParams.get('img');
    this.images = this.navParams.get('images');
    this.indice = this.navParams.get('indice');
    console.log('indice-->' + this.indice);

    this.arrayimages = JSON.parse(this.images);
    console.log('count-->' + this.arrayimages.length);

    console.log('array_images-->' , this.arrayimages);
    for (let i = 0 ; i < this.arrayimages.length  ; i++ ) {
        this.arrayimages[i].url = this.arrayimages[i].url.replace('thumbs', 'images');
    }
    this.img = this.arrayimages[this.indice].url;
    console.log('IMG-->' + this.img);
  }

  zoom(zoomIn: boolean) {
    const zoom = this.slider.nativeElement.swiper.zoom;
    if (zoomIn) {
      zoom.in();
    } else {
      zoom.out();
    }
  }

  close() {
    this.modalController.dismiss(null, undefined);
  }

  async functionToast(mensaje) {

    const toast = await this.toastCtrl.create({
          message: mensaje,
          showCloseButton: true,
          position: 'bottom',
          duration: 2000,
          closeButtonText: 'Cerrar',
          animated: true
        }
    );
    return toast.present();
  }

  next() {

    console.log('actual_next-->' + this.indice);

    if ( this.indice < (this.arrayimages.length - 1) ) {
      this.indice = this.indice + 1;
      this.img = this.arrayimages[this.indice].url;
    } else {
      this.functionToast('No puedes ir hacia delante');

    }
    console.log('next-->' + this.indice);

  }

  prev() {

    console.log('actual_prev-->' + this.indice);

    if ( this.indice > 0 ) {
      this.indice = this.indice - 1;
      this.img = this.arrayimages[this.indice].url;
    } else {
      this.functionToast('No puedes ir hacia atrás');

    }
    console.log('prev-->' + this.indice);

  }

}
