import { NgModule } from '@angular/core';
import {PreloadAllModules, Router, RouterModule, Routes} from '@angular/router';
import {NavController, Platform} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {TranslateService} from '@ngx-translate/core';
import {Storage} from '@ionic/storage';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'home', loadChildren: './home/home.module#HomePageModule' },
  { path: 'enlace', loadChildren: './ceremonia/ceremonia.module#CeremoniaPageModule' },
  { path: 'celebracion', loadChildren: './convite/convite.module#ConvitePageModule' },
  { path: 'album', loadChildren: './album/album.module#AlbumPageModule' },
  { path: 'contacto', loadChildren: './contacto/contacto.module#ContactoPageModule' },
  { path: 'login', loadChildren: './login/login.module#LoginPageModule' },
  { path: 'register', loadChildren: './register/register.module#RegisterPageModule' },
  { path: 'image-modal', loadChildren: './image-modal/image-modal.module#ImageModalPageModule' },
  { path: 'chat', loadChildren: './chat/chat.module#ChatPageModule' },
  { path: 'subir-imagen', loadChildren: './subir-imagen/subir-imagen.module#SubirImagenPageModule' },

];

@NgModule({imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {

  constructor() {

  }

}
